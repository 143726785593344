import request, {noop, parseQueryString, throwErr} from '../utils/request'
import Session from '../session'
import WeixinApi from '../weixin/weixin-api'
import {payPlatform} from '../utils/platform'
import {
    ALIPAY_URL,
    IAlipayResponse,
    INativeRepayResponse,
    IWxpayResponse, NATIVE_REPAY,
    PayOptions,
    WXPAY_URL
} from './pay-constrains'
import Variable from '../utils/variable'
import { getQueryParameter } from '../utils'
// import {createConfig} from '@weike/envconf-panel'
const GlobalVariable = new Variable().GlobalVariable

const payConf = {
    API_BASE_URL: GlobalVariable.API_BASE_URL
}

// const Config = createConfig('WxpayConfig', payConf)
const Config = payConf

type OnFailed = (err: any) => any
type OnSuccess<T> = (data: T) => any

type PayResult = ({
    type: 'alipay'
    payUrl: string
} | {
    type: 'wxpay'
    invoiceId: number
} | {
    type: 'qrcode'
    codeUrl: string
}) & { data: any }

class PayManager {

    private readonly session: Session
    private readonly weixinApi?: WeixinApi

    constructor(session: Session, weixinApi?: WeixinApi) {
        this.session = session
        this.weixinApi = weixinApi
    }

    public pay(options: PayOptions,
               onSuccess: OnSuccess<PayResult> = noop,
               onFailed: OnFailed = throwErr,
               onCancel: () => any = noop,
               extraOption: {
                   platform?: 'alipy' | 'weixin' | 'pc',
                   onOrder?: (result: PayResult) => void
                } = {}
            ) {
        let query = parseQueryString(location.search)
        const weixinApi = this.weixinApi
        const handlePlatform = extraOption.platform || payPlatform

        // 梨花班班个销：如果search带有from_type & from_id 且为专栏详情页支付
        const from_type = getQueryParameter('from_type')
        const from_id = getQueryParameter('from_id')
        if (
          from_type
          && from_id
          && options.product === 'pay_channel'
        ) {
          delete query['from_type']
          delete query['from_id']
          /**
           * from_type: 来源类型
           * from_id: 来源对象id
           * scene: 具体场景，info_page表示详情页
           */
          query = {
            ...query,
            business_from: JSON.stringify({
              from_type, 
              from_id,
              scene: 'info_page'
            })
          }
        }

        if (handlePlatform === 'alipy') {
            // 支付宝付款
            this.getAlipay(options, query, (data) => onSuccess({
                type: 'alipay',
                payUrl: data.data.params.pay_url,
                data
            }), onFailed)

        } else if (handlePlatform === 'weixin' && weixinApi && (weixinApi.status.wxReady || weixinApi.status.wxBridgeReady)) {
            // 微信内支付
            this.getWxpay(options, query, (data) => {
                
                // 获取订单信息时，调用extraOption.onOrder
                extraOption.onOrder && extraOption.onOrder({type: 'wxpay', invoiceId: data.invoice_id, data})

                const success = () => {
                    if (options.product === 'pay_channel' && query.platform && query.user_sign) {
                        this.bindOuterAccount(data.invoice_id, query.platform, query.user_sign)
                    }
                    onSuccess({type: 'wxpay', invoiceId: data.invoice_id, data})
                }

                if (options.fee === 0) {
                    success()
                } else {
                    weixinApi.wx.chooseWXPay({
                        ...data.params,
                        success,
                        fail: onFailed,
                        cancel: onCancel
                    })
                }
            }, onFailed)

        } else {
            // 外部调用微信二维码
            this.getWxpay(options, {...query, trade_type: 'NATIVE' }, (wxData) => {

                if (options.fee === 0) {
                    onSuccess({
                        type: 'qrcode',
                        codeUrl: '',
                        data: wxData
                    })
                } else {
                    // 不再调用/api/pay/native_repay接口；TAPD：https://www.tapd.cn/20219201/prong/stories/view/1120219201001041262
                    if (query.platform && query.user_sign) {
                        this.bindOuterAccount(wxData.invoice_id, query.platform, query.user_sign)
                    }

                    // 获取订单信息时，调用extraOption.onOrder
                    extraOption.onOrder && extraOption.onOrder({type: 'wxpay', invoiceId: wxData.invoice_id, data: wxData})
                    
                    onSuccess({
                        type: 'qrcode',
                        codeUrl: wxData.params.code_url || '',
                        data: wxData
                    })
                }
            }, onFailed)
        }
    }

    private getWxpay(options: PayOptions,
                     query: { [key: string]: string },
                     onSuccess: OnSuccess<IWxpayResponse>,
                     onFailed: OnFailed) {
        this.session.getToken(({token}) => {
            return request({
                method: 'GET',
                baseUrl: Config.API_BASE_URL,
                url: WXPAY_URL,
                query: {...query, ...options, token}
            }, (data) => {
                if(data.code === 0){
                    onSuccess(data)
                }else{
                    onFailed(data)
                }
            }, onFailed)
        }, onFailed)
    }

    private getAlipay(options: PayOptions,
                      query: { [key: string]: string },
                      onSuccess: OnSuccess<IAlipayResponse>,
                      onFailed: OnFailed) {
        this.session.getToken(({token}) => {
            return request({
                method: 'GET',
                baseUrl: Config.API_BASE_URL,
                url: ALIPAY_URL,
                query: {...query, ...options, token}
            }, (data) => data.code === 0 ? onSuccess(data) : onFailed(data), onFailed)
        }, onFailed)
    }

    private getNativeRepay(invoiceId: number,
                           onSuccess: OnSuccess<INativeRepayResponse>,
                           onFailed: OnFailed) {
        this.session.getToken(({token}) => {
            return request({
                method: 'POST',
                baseUrl: Config.API_BASE_URL,
                url: NATIVE_REPAY,
                query: {token},
                dataType: 'form',
                data: {invoice_id: invoiceId}
            }, (data) => data.code === 0 ? onSuccess(data) : onFailed(data), onFailed)
        }, onFailed)
    }

    private bindOuterAccount(invoiceId: number, platform: string, userSign: string) {
        this.session.getToken(({token}) => {
            request({
                baseUrl: Config.API_BASE_URL,
                url: `/api/invoice/${invoiceId}/bind_outer_account`,
                query: {token},
                method: 'POST', dataType: 'form',
                data: {platform, user_sign: userSign}
            })
        })
    }

}

export default PayManager
